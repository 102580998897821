import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

class AboutUs extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section bg-light" id="features">
          <Container>
            <Row className="vertical-content">
              <Col lg={5}>
                <div className="features-box">
                  <h3>
                  Travel with Confidence: Empowering Parents to Explore the World with Our Trustworthy TravelMate
                  </h3>
                  <p className="text-muted web-desc">
                  Our Dedicated Team: Committed to Bringing Joy and Connection to Elderly Parents' Travels, Ensuring Unforgettable Memories for the Whole Family
                  </p>
                  <ul className="text-muted list-unstyled mt-4 features-item-list">
                    <li className="">
                    Expertise in Elderly Travel
                    </li>
                    <li className="">
                    Compassionate Care
                    </li>
                    <li className="">Safety and Security Focus</li>
                    <li className="">Seamless Communication</li>
                  </ul>
                  <Link
                    to="#"
                    className="btn btn-primary mt-4 waves-effect waves-light"
                  >
                    Learn More <i className="mdi mdi-arrow-right"></i>
                  </Link>
                </div>
              </Col>
              <Col lg={7}>
                <div className="features-img features-right text-end">
                  <img
                    src="assets/images/online-world.svg"
                    alt="macbook"
                    className="img-fluid"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default AboutUs;
