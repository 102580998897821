import React, { Component } from "react";
import { Container, Row, Col, Form, Input, Button } from "reactstrap";
import { Link } from "react-router-dom";
import LocationForm from "../../components/LocationForm";

class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <section
          className="section bg-home home-half home-registration"
          id="home"
        >
          <div className="bg-overlay"></div>
          <div className="display-table">
            <div className="display-table-cell">
              <Container>
                <Row className="vertical-content">
                  <Col lg={7} className="text-white text-left mt-4">
                  
                  <div> 
                    <h2 className="text-red text-left mt-4">We have Travelmates availability in June and August for India Travel!</h2>
                    <span classname="text-red text-left mt-4">Send an email to us at support@parentstravelmate.com for immediate booking!</span>
                  </div>

                    <h1 className="home-title">
                      We help you find travelmates for Parents
                    </h1>
                    <p className="pt-3 home-desc home-subtitle-width-100">
                    Our website is designed to connect your parents with travel partners who are on the same flight or have a similar itinerary. This will give you peace of mind, especially if your parents are not aware of international/domestic travel requirements or there is a language barrier.
                    </p>
                    <Link
                      to="#"
                      className="btn btn-primary mt-4 waves-effect waves-light"
                    >
                      Get Started <i className="mdi mdi-arrow-right"></i>
                    </Link>
                  </Col>
                  <Col lg={{ size: 4, offset: 1 }} className="mt-4">
                    <div className="home-registration-form bg-white">
                      <h4 className="form-heading text-center mt-2">
                        Request Parents Travelmate
                      </h4>
                      <LocationForm></LocationForm>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
