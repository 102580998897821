import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";
import ServiceBox from "./services-box";

class Process extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services1: [
        {
          icon: "pe-7s-diamond",
          title: "Easy to use",
          desc:
            "You just let us know what your origin, destination and dates are, and we will do all the hard work of matching you with a travel partner.",
        },
        {
          icon: "pe-7s-display2",
          title: "Privacy at the core",
          desc:
            "You'll interact with your travel partner after our team has verified their travel details. Both traveler and travel mate identities are concealed until the verification is done.",
        },
        {
          icon: "pe-7s-piggy",
          title: "Communication",
          desc:
            "Chat with your travel partner in a group where our team mate will be the coordinator. Key updates via messaging on your phone.",
        },
      ],
      services2: [
        {
          icon: "pe-7s-science",
          title: "Awesome Support",
          desc:
            "It is a paradisematic country, in which roasted parts of sentences fly into your mouth leave for the far World.",
        },
        {
          icon: "pe-7s-news-paper",
          title: "Truly Multipurpose",
          desc:
            "Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic.",
        },
        {
          icon: "pe-7s-plane",
          title: "Easy to customize",
          desc:
            "Question Marks and devious Semikoli, but the Little Blind Text didn’t listen. She packed her seven versalia.",
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className={"section " + this.props.sectionClass} id="services">
          <Container>
            {/* section title */}
            <SectionTitle
              title="Our Services"
              desc="We give peace of mind to kids of Parents who are traveling internationally/domestically"
            />

            <Row className="mt-4">
              {/* services box */}
              <ServiceBox services={this.state.services1} />
            </Row>

            {/* <Row className="mt-4">
              <ServiceBox services={this.state.services2} />
            </Row> */}
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Process;
