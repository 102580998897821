import React, { useState } from 'react';
import { Container, Row, Col, Form, Input, Button } from "reactstrap";
import axios from 'axios';

const LocationForm = () => {
  const [origin, setOrigin] = useState('');
  const [destination, setDestination] = useState('');
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    console.log(fullName, email, origin, destination, message);
    setOrigin('');
    setDestination('');

    setIsSubmitting(true);

    try {
      const response = await axios.post('https://formspree.io/f/mwkjyaga', {
        fullName,
        email,
        origin,
        destination,
        message
      });

      if (response.status === 200) {
        setSubmissionMessage('Email sent successfully!');
        resetForm();
      } else {
        setSubmissionMessage('Error sending email.');
      }
    } catch (error) {
      setSubmissionMessage('Error sending email.');
    }

    setIsSubmitting(false);
    // resetForm();

  };

  const resetForm = () => {
    setFullName('');
    setOrigin('');
    setDestination('');
    setEmail('');
    setMessage('');

    document.getElementById('registration-form').reset();

  };

  return (
    // <div>{submissionMessage && <p>{submissionMessage}</p>}</div>
    <div>
        {submissionMessage && <p>{submissionMessage}</p>}
    <Form
        className="registration-form"
        id="registration-form"
        onSubmit={handleSubmit}
        // action='https://formspree.io/f/mwkjyaga'
        // method='POST'
    >
        <Input
            type="text"
            id="fullName"
            name="fullName"
            className="form-control registration-input-box"
            placeholder="Name"
            onChange= { (event) => setFullName(event.target.value)}
        />
        <Input
            type="email"
            id="email"
            name="email"
            className="form-control registration-input-box"
            placeholder="Email"
            onChange= { (event) => setEmail(event.target.value)}
        />
        {/* <Location ></Location> */}
        <Input
            type="text"
            id="origin"
            name="origin"
            className="form-control registration-input-box"
            placeholder="Origin"
            onChange={(event) => setOrigin(event.target.value)}
        />
        <Input
            type="text"
            id="destination"
            name="destination"
            className="form-control registration-input-box"
            placeholder="Destination"
            onChange={(event) => setDestination(event.target.value)}
        />
        <textarea
            className="form-control registration-textarea-box"
            id="message"
            name="message"
            rows="4"
            placeholder="More details about travelers and if any special requests you have?"
            onChange={(event) => setMessage(event.target.value)}
        ></textarea>
        <Button
            color="none"
            className="btn-primary w-100 home-btn-width waves-effect waves-light"
            disabled={isSubmitting}
        > {isSubmitting ? 'Sending Request...' : 'Request Travel'}
            {/* Request Travel */}
        </Button>
    </Form>
    </div>
  );
};

export default LocationForm;